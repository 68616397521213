import React from "react";
import styled from "styled-components";
import { Body20 } from "./styles/BodyTypes";
import Container from "./styles/Container";
import { mediaMedium } from "./styles/variables";

const Content = styled(Body20)`
  text-align: center;
  margin: 0;
  padding: 86px 0;

  @media (${mediaMedium}) {
    padding: 51px 0;
  }
`;

const CenterText = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};

export default CenterText;
