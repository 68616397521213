import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { mediaMedium } from "../styles/variables";
import Image from "../Image";

const Container = styled.div`
  pointer-events: none;

  @media (${mediaMedium}) {
    width: 680px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    max-width: 100%;
    margin: 0 auto;
    max-height: 705px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding-top: 93.6%;
`;

const Top = styled.div`
  position: absolute;
  left: 0;
  transform: translateX(-30%);
  top: -15%;
  z-index: 2;
  width: 100%;

  @media (${mediaMedium}) {
    width: 70%;
    transform: none;
  }
`;

const Bottom = styled(Top)`
  z-index: 3;
  top: 25%;
  left: calc(40% - 25px);
  transform: translateX(0%);

  @media (${mediaMedium}) {
    z-index: 5;
    left: calc(50% - 25px);

    transform: none;
  }
`;

const DistributionAnimation = () => {
  const Images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "46-commerce-distribution-1.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      two: file(relativePath: { eq: "46-commerce-distribution-2.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Wrapper>
        <Top>
          <Image fluid={Images.one.childImageSharp.fluid} />
        </Top>

        <Bottom>
          <Image fluid={Images.two.childImageSharp.fluid} />
        </Bottom>
      </Wrapper>
    </Container>
  );
};

export default DistributionAnimation;
