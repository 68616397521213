import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import CommerceBrands from "../publishers/CommerceBrands";
import CommerceShopSection from "../publishers/CommerceShopSection";
import HeroSection from "../HeroSection";
import TextHighlight from "../styles/TextHighlight";
import { black1, brandColor2, gray4, white } from "../styles/Colors";
import CTALink from "../styles/CTALink";
import CenterText from "../CenterText";
import HeroTwoColumns from "../HeroTwoColumns";
import PagePadding from "../styles/PagePadding";
import HeroContentSnippet from "../HeroContentSnippet";
import ResponsiveImages from "../styles/ResponsiveImages";
import HeroMetricsThree from "../HeroMetricsThree";
import ShopFeatures from "../publishers/ShopFeatures";
import HeroTestimonial from "../HeroTestimonial";
import { Headline32 } from "../styles/Headings";
import HeroBlueCTA from "../HeroBlueCTA";
import MoreWaysToEarn from "../MoreWaysToEarn";
import styled from "styled-components";
import { mediaMedium } from "../styles/variables";
import AnimatedLineSvg from "../AnimatedLineSvg";
import DecorationRightTop from "../snippets/DecorationRightTop";
import { FadeInLeft, FadeInRight } from "../styles/animations";
import DistributionAnimation from "../publishers/DistributionAnimation";
import ContentAnimation from "../ContentAnimation";
import AnimationWrapper from "../AnimationWrapper";

// Images
import merchDesktop from "../../images/43-commerce-merch.png";

const Decoration = styled.div`
  pointer-events: none;
  display: none;

  @media (${mediaMedium}) {
    display: block;
    position: absolute;
    top: -157px;
    left: -128%;
    z-index: 1;
  }
`;

const FadeInLeftSmall = styled(FadeInLeft)`
  transform: translateX(${(props) => (props.active ? 0 : -30)}%);
`;

const PublishersShopsDefer = () => {
  const images = useStaticQuery(graphql`
    query {
      logoTestimonial: file(
        relativePath: { eq: "38-testimonial-commercer-logo.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTestimonial: file(
        relativePath: { eq: "38-testimonial-commerce-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileTestimonial: file(
        relativePath: { eq: "38-testimonial-commerce-mobile.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopMetrics: file(relativePath: { eq: "10-metric-2x.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileMetrics: file(relativePath: { eq: "10-metric.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      caseStudyDesktop: file(
        relativePath: { eq: "47-commerce-case-study-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      caseStudyMobile: file(
        relativePath: { eq: "47-commerce-case-study-mobile.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      productsDesktop: file(
        relativePath: { eq: "45-commerce-speaker-desktop.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      productsMobile: file(
        relativePath: { eq: "45-commerce-speaker-mobile.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      contentOne: file(relativePath: { eq: "44-commerce-content-1.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      contentTwo: file(relativePath: { eq: "44-commerce-content-2.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <CommerceBrands />

      <CommerceShopSection />

      <HeroSection
        title="products"
        top="02"
        theme={{
          imageTop: "0",
          imageLeft: "40%",
          paddingMedia: "105px 0 24px"
        }}
        contentWrapper="div"
        animation="div"
        images={{
          desktop: {
            fluid: images.productsDesktop.childImageSharp.fluid
          },
          mobile: {
            fluid: images.productsMobile.childImageSharp.fluid
          }
        }}
      >
        <p>
          We provide one-of-a-kind deals to your audience and higher margins to
          you by cutting out the middleman.
        </p>
        <p>
          <TextHighlight theme={{ background: white }}>
            100% merchant-direct
          </TextHighlight>{" "}
          deals means more money in your pocket and better deals for your
          readers that you can't find on any affiliate network.
        </p>
      </HeroSection>

      <HeroSection
        contentWrapper="div"
        top="03"
        title="CONTENT"
        reverse={true}
        alt={true}
        theme={{
          paddingMedia: "105px 0 0"
        }}
        backgroundColor={brandColor2}
        displayComponent={<ContentAnimation images={images} />}
      >
        <p>
          Stack’s Content Studio writes original articles based on your style
          guidelines — scaling your commerce content without requiring more
          resources.
        </p>
        <p>
          Find out more about Stack's{" "}
          <CTALink
            attrs={{
              to: "/publishers/content-studio/"
            }}
            variant="white"
            bold
            underlined
          >
            Content Studio
          </CTALink>
          .
        </p>
      </HeroSection>

      <HeroSection
        title="MERCH"
        top="04"
        theme={{ imageTop: "24px", imageLeft: "initial", imageRight: "46%" }}
        contentWrapper="div"
        alt={true}
        backgroundImage={merchDesktop}
      >
        <p>
          We bring custom, on-demand products featuring your brand to your
          biggest fans.
        </p>
      </HeroSection>

      <HeroSection
        title="EXPERTS"
        top="05"
        images={null}
        animation={FadeInLeftSmall}
        theme={{ imageTop: "24px", imageLeft: "initial", imageRight: "46%" }}
        contentWrapper="div"
        reverse={true}
      >
        <Decoration>
          <AnimatedLineSvg duration={1000} triggerOffset={50}>
            <DecorationRightTop />
          </AnimatedLineSvg>
        </Decoration>
        <p>
          Stack is nothing like a typical affiliate program. We have a decade of
          experience helping optimize commerce solutions, and you’ll have a real
          human guiding you every step of the way.
        </p>
      </HeroSection>

      <HeroSection
        title="DISTRIBUTION"
        top="06"
        theme={{ imageTop: "-12%", paddingMedia: "105px 0 0" }}
        contentWrapper="div"
        alt={true}
        displayComponent={<DistributionAnimation />}
      >
        <p>
          We take a data-driven approach to distribute your content where your
          readers are most engaged, from email to paid media.
        </p>
        <p>
          With typical affiliate, only the retailer benefits from future
          marketing efforts. With Stack, publishers see revenue from an average
          of{" "}
          <TextHighlight theme={{ color: white, background: black1 }}>
            2x the purchases
          </TextHighlight>{" "}
          per customer.
        </p>
      </HeroSection>

      <CenterText>
        Have a small (but mighty) audience size, and still interested in
        launching a branded Shop? Check out our{" "}
        <CTALink attrs={{ to: "/publishers/self-serve-shops/" }}>
          Self-Serve Shops.
        </CTALink>
      </CenterText>

      <HeroTwoColumns align="center" background={gray4}>
        <PagePadding>
          <HeroContentSnippet
            title="ENTREPRENEUR x STACK"
            subTitle="CASE STUDY"
            link={{ to: "/case-study/entrepreneur/" }}
            noTrail={true}
            label="Check It Out"
            padding="112px 0"
            paddingMedia="115px 0 0"
          >
            How we partnered with the leading magazine for small businesses to
            build a million-dollar commerce business.
          </HeroContentSnippet>
        </PagePadding>
        <AnimationWrapper component={FadeInRight}>
          <ResponsiveImages
            images={[
              {
                fluid: images.caseStudyDesktop.childImageSharp.fluid
              },
              {
                fluid: images.caseStudyMobile.childImageSharp.fluid
              }
            ]}
          />
        </AnimationWrapper>
      </HeroTwoColumns>

      <HeroMetricsThree
        backgroundMedia={images.mobileMetrics}
        backgroundDesktop={images.desktopMetrics}
        content={[
          {
            number: "60",
            suffix: "%",
            description: "Of the Comscore Top 25 Publishers Use StackCommerce"
          },
          {
            number: "40",
            suffix: "%",
            description: "Of Revenue Comes From <br/> Repeat Purchasers"
          },
          {
            number: "1",
            suffix: "k+",
            description: "Publishers in Our Network"
          }
        ]}
      />

      <ShopFeatures />

      <HeroTestimonial
        media={images}
        author="Amy Schellenbaum | Group Digital Director @ Bonnier Corp"
      >
        <Headline32 theme={{ margins: "18px 0", color: white }}>
          StackCommerce is a great revenue partner for us. From their quality
          content and product selection to their super responsive team members,
          working with them is a seamless and enjoyable process.
        </Headline32>
      </HeroTestimonial>

      <HeroBlueCTA
        title="LET’S BUILD"
        outline="YOU A SHOP"
        description="It’s easy—just tell us a little bit about your site and audience and you’ll be on your way."
        link={{ to: "/contact/?smopt=publisher", state: { from: "publisher" }, as: Link }}
      />

      <MoreWaysToEarn noSelfShops={true} />
    </>
  );
};

export default PublishersShopsDefer;
