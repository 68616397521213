import React from "react";
import styled from "styled-components";
import { mediaMedium } from "./variables";
import Image from "../Image";

const Mobile = styled(Image)`
  display: block;

  &.gatsby-image-wrapper {
    width: 100%;

    > img {
      height: 100% !important;
    }
  }

  @media (${mediaMedium}) {
    display: none;
  }
`;

const Desktop = styled(Image)`
  display: none;

  @media (${mediaMedium}) {
    display: block;
  }
`;

const Wrapper = styled.div`
  img {
    max-width: 100% !important;
    width: 100%;
  }
`;

const ResponsiveImages = ({ images }) => {
  return (
    <Wrapper>
      <Desktop {...images[0]} />
      <Mobile {...images[1]} />
    </Wrapper>
  );
};

export default ResponsiveImages;
