import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled, { css } from "styled-components";
import { mediaMedium } from "../styles/variables";
import Image from "../Image";
import { SlideInBottom, SlideInTop } from "../styles/animations";
import { useInView } from "react-intersection-observer";

const Container = styled.div`
  pointer-events: none;
  width: 1540px;
  max-width: 100%;
  margin: 0 auto;
  max-height: 880px;
`;

const Wrapper = styled.div`
  position: relative;
  padding-top: 93.6%;
`;

const Left = styled.div`
  position: absolute;
  left: -30%;
  top: 0;
  z-index: 2;
  width: 90%;

  @media (${mediaMedium}) {
    left: 0;
  }
`;

const Middle = styled(Left)`
  position: absolute;
  z-index: 3;
  left: 30%;
  display: none;

  @media (${mediaMedium}) {
    display: block;
    left: 30%;
  }
`;

const Right = styled(Left)`
  z-index: 3;
  left: 25%;

  @media (${mediaMedium}) {
    left: 61%;
    z-index: 5;
  }
`;

const Animation = styled(Image)`
  transform: translateY(20%);
  animation: ${(props) =>
    props.active
      ? css`
          ${SlideInBottom} 0.9s ease forwards 0.1s
        `
      : "none"};
`;

const AnimationMiddle = styled(Image)`
  transform: translateY(-20%);
  animation: ${(props) =>
    props.active
      ? css`
          ${SlideInTop} 0.9s ease forwards 0.1s
        `
      : "none"};
`;

const AnimationRight = styled(Image)`
  transform: translateY(20%);
  animation: ${(props) =>
    props.active
      ? css`
          ${SlideInTop} 0.9s ease forwards 0.1s
        `
      : "none"};

  @media (${mediaMedium}) {
    animation: ${(props) =>
      props.active
        ? css`
            ${SlideInBottom} 0.9s ease forwards 0.1s
          `
        : "none"};
  }
`;

const Trigger = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  transform: translateY(-35%);
`;

const Threshold = [0, 0.2, 0.5, 1];

const ToolkitAnimation = () => {
  const Images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "48-commerce-shops-1.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      two: file(relativePath: { eq: "48-commerce-shops-2.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      three: file(relativePath: { eq: "48-commerce-shops-3.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const { ref, inView } = useInView({
    threshold: Threshold,
    delay: 30,
    triggerOnce: true
  });

  return (
    <Container>
      <Wrapper>
        <Trigger ref={ref} />
        <Left>
          <Animation fluid={Images.one.childImageSharp.fluid} active={inView} />
        </Left>

        <Middle>
          <AnimationMiddle
            fluid={Images.two.childImageSharp.fluid}
            active={inView}
          />
        </Middle>

        <Right>
          <AnimationRight
            fluid={Images.three.childImageSharp.fluid}
            active={inView}
          />
        </Right>
      </Wrapper>
    </Container>
  );
};

export default ToolkitAnimation;
