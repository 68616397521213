import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import OutlinedText from "../styles/OutlinedText";
import { Headline80, Headline32 } from "../styles/Headings";
import { Body20 } from "../styles/BodyTypes";
import { mediaMedium } from "../styles/variables";

const features = [
  {
    title: "storefront",
    items: [
      "White-Label Technology",
      "Merchant-Direct Deals",
      "New Products Weekly",
      "Collections + Buyer’s Guides",
      "Merchant Integrations",
      "Copywriting + Design",
      "AI Personalization",
      "Multimedia",
      "Smart Upsells",
      "Optimized Checkout",
      "Branded Merchandise Integration"
    ]
  },
  {
    title: "operations",
    items: [
      "Dedicated Account Management",
      "Customer Support",
      "Fraud Detection",
      "Technical Support",
      "Payment Processing",
      "Accessibility Compliant",
      "GDPR Compliant",
      "Digital Product Redemption",
      "Shipping + Fullfillment",
      "eLearning Course Hosting",
      "CCPA Compliant"
    ]
  },
  {
    title: "marketing",
    items: [
      "Paid Ads",
      "Shoppable Emails",
      "SMS Messaging",
      "Facebook Messaging",
      "Sale Campaigns",
      "Web Push Notifications",
      "Direct Mail Campaigns",
      "TV Campaigns",
      "Exclusive Product Bundling",
      "Co-Branded Sweepstakes",
      "SEO Optimization"
    ]
  }
];

const Wrapper = styled.div`
  padding: 91px 0 86px;

  @media (${mediaMedium}) {
    padding: 144px 0 147px;
  }
`;

const Top = styled.div`
  margin-bottom: 48px;

  @media (${mediaMedium}) {
    margin-bottom: 84px;
  }
`;

const Grid = styled.div`
  @media (${mediaMedium}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: calc(100% * 1 / 12);
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (${mediaMedium}) {
    margin-bottom: 0;
  }
`;

const ListItem = styled(Body20)`
  position: relative;
  margin: 0 0 26px;
  padding: 0 0 0 35px;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 17px;
    height: 17px;
    background: url("/images/icon-star.svg") 50% 50% no-repeat;
    background-size: contain;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (${mediaMedium}) {
    &:before {
      top: 8px;
    }
  }
`;

const Section = styled.div`
  margin: 0 0 54px;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media (${mediaMedium}) {
    margin-bottom: 0;
  }
`;

const ShopFeatures = () => {
  return (
    <Wrapper>
      <Container>
        <Top>
          <Headline80>
            SHOP <br />
            <OutlinedText as="span" component="span">
              FEATURES
            </OutlinedText>
          </Headline80>
        </Top>
        <Grid>
          {features.map((section, key) => (
            <Section key={`section${key}`}>
              <Headline32 theme={{ margins: "0 0 30px" }}>
                {section.title}
              </Headline32>
              <List>
                {section.items.map((item, index) => (
                  <ListItem as="li" key={`item${index}-${key}`}>
                    {item}
                  </ListItem>
                ))}
              </List>
            </Section>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default ShopFeatures;
