import React from "react";
import styled, { css } from "styled-components";
import { mediaMedium, mediaTablet } from "./styles/variables";
import Image from "./Image";
import { FlyBy } from "./styles/animations";
import { useInView } from "react-intersection-observer";

const Container = styled.div`
  pointer-events: none;
  max-width: 150%;
  width: 150%;
  padding-top: 24px;
  max-height: 300px;
  transform: translateX(-25%);
  position: relative;

  @media (${mediaTablet}) {
    max-height: 480px;
    max-width: 100%;
    width: 100%;
    transform: none;
  }

  @media (${mediaMedium}) {
    max-width: 700px;
    width: 53vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    overflow: visible;
    transform: none;
  }

  @media (min-width: 1140px) {
    left: 8%;
  }

  @media (min-width: 2040px) {
    left: 22%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding-top: 120.6%;
`;

const Top = styled.div`
  position: absolute;
  top: -10%;
  z-index: 2;
  width: 80%;
  transform: translateX(-10%);

  @media (${mediaMedium}) {
    width: 100%;
    left: 0;
    transform: translateX(-40%);
  }
`;

const Bottom = styled(Top)`
  z-index: 3;
  top: 15%;
  left: 0;
  transform: translateX(24%);

  @media (${mediaMedium}) {
    z-index: 5;
    top: 25%;
    transform: translateX(-8%);
  }
`;

const Animation = styled(Image)`
  transform: translateY(20%) translateX(-20%);
  animation: ${(props) =>
    props.active
      ? css`
          ${FlyBy} 0.9s ease forwards 0.1s
        `
      : "none"};
`;

const Trigger = styled.div`
  position: absolute;
  width: 100%;
  height: 100vw;
  top: 0;
  transform: translateY(-30%);
  left: 0;
  pointer-events: none;

  @media (${mediaMedium}) {
    height: 50vw;
    transform: translateY(0);
  }
`;

const Threshold = [0, 0.3, 0.5, 0.7, 1];

const ContentAnimation = ({ images }) => {
  const { ref, inView } = useInView({
    threshold: Threshold,
    delay: 30,
    triggerOnce: true
  });

  return (
    <Container>
      <Trigger ref={ref} />
      <Wrapper>
        <Top>
          <Animation
            fluid={images.contentOne.childImageSharp.fluid}
            active={inView}
          />
        </Top>

        <Bottom>
          <Animation
            fluid={images.contentTwo.childImageSharp.fluid}
            active={inView}
          />
        </Bottom>
      </Wrapper>
    </Container>
  );
};

export default ContentAnimation;
