import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Image from "../Image";
import Container from "../styles/Container";
import { black1 } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import MoveInDiv from "../MoveInDiv";

const Background = styled.div`
  background-color: ${black1};
  padding: 108px 0 107px;

  @media (${mediaMedium}) {
    padding: 153px 0 156px;
  }
`;

const Item = styled.div`
  grid-column-end: span 1;

  @media (${mediaMedium}) {
    grid-column-end: span 12;
    order: 1 !important;
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  grid-row-gap: 58px;
  grid-column-gap: 58px;
  grid-template-columns: repeat(3, 1fr);

  .gatsby-image-wrapper {
    margin: 0 auto;
    overflow: visible !important;
  }

  @media (max-width: 829px) {
    ${Item}:nth-child(3n) {
      .gatsby-image-wrapper {
        margin-right: 0;
      }
    }

    ${Item}:nth-child(3n-2) {
      .gatsby-image-wrapper {
        margin-left: 0;
      }
    }
  }

  @media (${mediaMedium}) {
    grid-row-gap: 98px;
    grid-column-gap: 0;
    grid-template-columns: repeat(60, 1fr);
    width: calc(100% - 32px);
    padding: 0 16px;

    ${Item}:nth-child(n + 6):nth-child(-n + 8),
    ${Item}:nth-child(n + 12):nth-child(-n + 14) {
      grid-column-end: span 8;
    }

    ${Item}:nth-child(1),
    ${Item}:nth-child(6),
    ${Item}:nth-child(12),
    ${Item}:nth-child(18),
    ${Item}:nth-child(23) {
      .gatsby-image-wrapper {
        margin-left: 0;
      }
    }

    ${Item}:nth-child(18),
    ${Item}:nth-child(23) {
      grid-column-end: span 8;
    }

    ${Item}:nth-child(n + 19):nth-child(-n + 22),
    ${Item}:nth-child(n + 24):nth-child(-n + 26) {
      grid-column-end: span 13;
    }

    ${Item}:nth-child(5),
    ${Item}:nth-child(11),
    ${Item}:nth-child(17),
    ${Item}:nth-child(22),
    ${Item}:nth-child(27) {
      .gatsby-image-wrapper {
        margin-right: 0;
      }
    }
  }
`;

const CommerceBrandsSection = ({ data }) => {
  return (
    <Background>
      <Container>
        <Grid>
          {data &&
            Object.keys(data).map((item, key) => (
              <Item key={item}>
                <MoveInDiv translateFrom={38} threshold={[0.4, 0.75]}>
                  <Image fluid={data[item].childImageSharp.fluid} />
                </MoveInDiv>
              </Item>
            ))}
        </Grid>
      </Container>
    </Background>
  );
};

const CommerceBrands = () => {
  const data = useStaticQuery(graphql`
    query {
      mashable: file(relativePath: { eq: "42-mashable.png" }) {
        childImageSharp {
          fluid(maxWidth: 128, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      engadget: file(relativePath: { eq: "42-engadget.png" }) {
        childImageSharp {
          fluid(maxWidth: 140, maxHeight: 31, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      marshall: file(relativePath: { eq: "42-yahoo.png" }) {
        childImageSharp {
          fluid(maxWidth: 124, maxHeight: 34, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      entrepreneur: file(relativePath: { eq: "42-entrepreneur.png" }) {
        childImageSharp {
          fluid(maxWidth: 153, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      huffpost: file(relativePath: { eq: "42-huffpost.png" }) {
        childImageSharp {
          fluid(maxWidth: 145, maxHeight: 17, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      goodMorningAmerica: file(
        relativePath: { eq: "42-good-morning-america.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 105, maxHeight: 55, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      pcMag: file(relativePath: { eq: "42-pcmag.png" }) {
        childImageSharp {
          fluid(maxWidth: 52, maxHeight: 62, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      nyPost: file(relativePath: { eq: "42-nypost.png" }) {
        childImageSharp {
          fluid(maxWidth: 101, maxHeight: 49, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      kellyClarksonShow: file(
        relativePath: { eq: "42-kelly-clarkson-show.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 114, maxHeight: 52, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      steveHarvey: file(relativePath: { eq: "42-steve-harvey.png" }) {
        childImageSharp {
          fluid(maxWidth: 124, maxHeight: 37, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      gizModo: file(relativePath: { eq: "42-gizmodo.png" }) {
        childImageSharp {
          fluid(maxWidth: 146, maxHeight: 21, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      ign: file(relativePath: { eq: "42-IGN.png" }) {
        childImageSharp {
          fluid(maxWidth: 109, maxHeight: 33, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cnet: file(relativePath: { eq: "42-cnet.png" }) {
        childImageSharp {
          fluid(maxWidth: 59, maxHeight: 59, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      usWeekly: file(relativePath: { eq: "42-usweekly.png" }) {
        childImageSharp {
          fluid(maxWidth: 51, maxHeight: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cnn: file(relativePath: { eq: "42-cnn.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 38, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      dailyMail: file(relativePath: { eq: "42-dailymail.png" }) {
        childImageSharp {
          fluid(maxWidth: 179, maxHeight: 28, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      engadget1: file(relativePath: { eq: "42-engadget.png" }) {
        childImageSharp {
          fluid(maxWidth: 140, maxHeight: 31, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      tmz: file(relativePath: { eq: "42-tmz.png" }) {
        childImageSharp {
          fluid(maxWidth: 86, maxHeight: 35, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      futurism: file(relativePath: { eq: "42-futurism.png" }) {
        childImageSharp {
          fluid(maxWidth: 109, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      businessInsider: file(relativePath: { eq: "42-business-insider.png" }) {
        childImageSharp {
          fluid(maxWidth: 108, maxHeight: 35, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      techcrunch: file(relativePath: { eq: "42-techcrunch.png" }) {
        childImageSharp {
          fluid(maxWidth: 178, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      theChive: file(relativePath: { eq: "42-the-chive.png" }) {
        childImageSharp {
          fluid(maxWidth: 132, maxHeight: 27, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileNations: file(relativePath: { eq: "42-mobile-nations.png" }) {
        childImageSharp {
          fluid(maxWidth: 122, maxHeight: 42, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      popularScience: file(relativePath: { eq: "42-popular-science.png" }) {
        childImageSharp {
          fluid(maxWidth: 112, maxHeight: 40, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mensJournal: file(relativePath: { eq: "42-mens-journal.png" }) {
        childImageSharp {
          fluid(maxWidth: 140, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      interestingEngineering: file(
        relativePath: { eq: "42-interesting-engineering.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 114, maxHeight: 34, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      marketwatch: file(relativePath: { eq: "42-marketwatch.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <CommerceBrandsSection data={data} />;
};

export default CommerceBrands;
