import React from "react";
import styled from "styled-components";
import ContentSnippet from "./ContentSnippet";
import Container from "./styles/Container";
import { mediaMedium } from "./styles/variables";
import Image from "./Image";
import { black1, white } from "./styles/Colors";
import AnimationWrapper from "./AnimationWrapper";

const Wrapper = styled.div`
  overflow: ${(props) =>
          props.theme.overflow ? props.theme.overflow : "hidden"};
  position: relative;
  z-index: 2;
  padding: ${(props) =>
    props.theme.paddingMedia ? props.theme.paddingMedia : "93px 0 0"};
  background: ${(props) =>
      props.backgroundImage ? `url(${props.backgroundImage})` : props.theme.backgroundColor ? props.theme.backgroundColor : ""}
    55% 0% no-repeat ${(props) => props.color};
  background-size: auto 100%;
  margin: ${(props) =>
    props.theme.marginMedia ? props.theme.marginMedia : "0"};
  ${(props) =>
          props.theme.marginTop && `margin-top: ${props.theme.marginTop};`};
  ${(props) =>
          props.theme.wrapperStyles && props.theme.wrapperStyles};
  
  @media (${mediaMedium}) {
    padding: ${(props) =>
      props.theme.paddingDesktop
        ? props.theme.paddingDesktop
        : "153px 0 127px"};
    background-size: cover;
    background-position: top center;
    margin: ${(props) =>
      props.theme.marginDesktop ? props.theme.marginDesktop : "0 auto"};
  }
`;

const Grid = styled.div`
  position: relative;

  @media (${mediaMedium}) {
    display: ${(props) => (props.noGrid ? "block" : "grid")};
    width: 100%;
    grid-template-columns: calc(100% * 5 / 12);
    justify-content: ${(props) => (props.reverse ? "end" : "start")};
  }
`;

const Overflow = styled.div`
  display: none;
  pointer-events: none;

  .gatsby-image-wrapper {
    width: 100%;
  }

  @media (${mediaMedium}) {
    display: block;
    position: absolute;
    right: ${(props) =>
      props.theme.imageRight ? props.theme.imageRight : "initial"};
    left: ${(props) => (props.theme.imageLeft ? props.theme.imageLeft : "45%")};
    top: ${(props) => (props.theme.imageTop ? props.theme.imageTop : "174px")};
    width: ${(props) => (props.divWidth ? props.divWidth : props.divWidth)}px;
    height: auto;
    max-width: ${(props) =>
      props.theme.overflowWidth ? `${props.theme.overflowWidth}px` : "none"};
  }
`;

const Display = styled(Image)`
  width: ${(props) => props.theme.imgWidth ? props.theme.imgWidth : "calc(100% + 40px)"};
  max-width: ${(props) => props.theme.imgWidth ? props.theme.imgWidth : "calc(100% + 40px)"};
  transform: translateX(${(props) => props.theme.imgTransform ?? "-20px"});

  @media (${mediaMedium}) {
    display: none;
  }
`;

const Content = styled.div`
  margin-bottom: ${(props) => props.theme.contentMarginBottom ?? "100px"};
  position: relative;
  z-index: 1;

  @media (${mediaMedium}) {
    margin-bottom: 0;
  }
`;

const Space = styled.span`
  position: absolute;
  top: 0;
  display: inline-block;
  width: 1px;
  margin-top: -5px;
  height: 5px;
`;

const Threshold = [0.05, 0.2, 0.3, 0.5, 0.75];

const HeroSection = ({
  animation,
  title,
  top,
  children,
  alt,
  images,
  theme,
  reverse,
  noGrid,
  noTextContent,
  contentWrapper,
  backgroundImage,
  backgroundColor,
  decoration,
  displayComponent
}) => {
  const Element = decoration ?? "";

  return (
    <Wrapper
      backgroundImage={backgroundImage}
      color={backgroundColor ? backgroundColor : alt ? black1 : white}
      theme={theme}
    >
      {Element}
      <Container>
        <Grid reverse={reverse} noGrid={noGrid}>
          <Content theme={theme}>
            {noTextContent ? children : (
              <ContentSnippet
                color={alt}
                title={title}
                top={top}
                contentWrapper={contentWrapper}
              >
                {children}
              </ContentSnippet>
            )}
          </Content>

          {!displayComponent && images && images.mobile && !animation && (
            <div>
              <Display {...images.mobile} theme={theme} alt={title} />
            </div>
          )}

          {!displayComponent && images && images.mobile && animation && (
            <AnimationWrapper threshold={Threshold} component={animation}>
              <Space />
              <Display {...images.mobile} theme={theme} alt={title} />
            </AnimationWrapper>
          )}
        </Grid>
      </Container>
      {displayComponent && displayComponent}
      {!displayComponent && images && images.desktop && (
        <Overflow
          theme={theme}
          divWidth={
            images.desktop.fluid
              ? images.desktop.fluid.maxWidth
              : images.desktop.width
          }
        >
          {!displayComponent && !animation && (
            <Image alt={title} {...images.desktop} />
          )}

          {!displayComponent && animation && (
            <AnimationWrapper threshold={Threshold} component={animation}>
              <Space />
              <Image alt={title} {...images.desktop} />
            </AnimationWrapper>
          )}
        </Overflow>
      )}
    </Wrapper>
  );
};

export default HeroSection;
