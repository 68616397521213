import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const Div = styled.div``;

const AnimationWrapper = ({
  component,
  children,
  threshold = [0, 0.5, 0.75],
  triggerOnce = true
}) => {
  const { ref, inView } = useInView({
    threshold: threshold,
    delay: 30,
    triggerOnce
  });

  return (
    <div ref={ref}>
      <Div as={component} active={inView}>
        {children}
      </Div>
    </div>
  );
};

export default AnimationWrapper;
