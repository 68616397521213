import React from "react";
import styled from "styled-components";
import { mediaMedium } from "./styles/variables";
import { brandColor3, white } from "./styles/Colors";
import { Headline64 } from "./styles/Headings";
import { Label16 } from "./styles/BodyTypes";

const Circle = styled.div`
  width: 208px;
  height: 208px;
  background-color: ${brandColor3};
  border-radius: 50%;
  display: grid;
  grid-template-rows: 40% 1fr;
  justify-items: center;

  @media (${mediaMedium}) {
    grid-template-rows: 48% 1fr;
    width: 264px;
    height: 264px;
  }
`;

const Title = styled(Headline64)`
  align-self: flex-end;
  margin-bottom: 8px;
`;

const Content = styled(Label16)`
  text-align: center;
  max-width: 85%;
`;

const CircleHighlight = ({ title, children }) => {
  return (
    <Circle>
      <Title as={"span"} theme={{ color: white }}>
        {title}
      </Title>
      <Content as="span">{children}</Content>
    </Circle>
  );
};

export default CircleHighlight;
