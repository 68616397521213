import React from "react";
import styled from "styled-components";
import HeroTwoColumns from "../HeroTwoColumns";
import ContentSnippet from "../ContentSnippet";
import Container from "../styles/Container";
import CircleHighlight from "../CircleHighlight";
import PagePadding from "../styles/PagePadding";
import { Headline80 } from "../styles/Headings";
import { Body20 } from "../styles/BodyTypes";
import { brandColor2, gray4, white } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import ToolkitAnimation from "./ToolkitAnimation";

const Background = styled.div`
  background-color: ${gray4};
  padding-bottom: 84px;
  padding-top: 103px;
  overflow: hidden;

  @media (${mediaMedium}) {
    padding-bottom: 84px;
    padding-top: 144px;
  }
`;

const Middle = styled.div`
  position: relative;
  max-width: 100vw;
  margin: 60px 0 40px;

  @media (${mediaMedium}) {
    margin: -36px 0 -20px;
  }
`;

const Circle = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 5;

  @media (${mediaMedium}) {
    left: 0;
  }
`;

const Wrapper = styled(Container)`
  position: relative;
`;

const TopTitle = styled.div`
  padding: 0 20px 40px;

  @media (${mediaMedium}) {
    padding: 0 8px;
  }
`;

const TopContent = styled.div`
  padding: 0 20px;
  position: relative;
  z-index: 2;

  @media (${mediaMedium}) {
    padding: 432px 8px 0;
  }
`;

const SnippetWrapper = styled.div`
  max-width: 410px;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 14px;
  }

  @media (${mediaMedium}) {
    margin-bottom: 83px;

    &:last-child {
      margin-bottom: 83px;
    }
  }
`;

const Title = styled(Body20)`
  font-weight: 800;
  margin: 0 0 5px;
`;

const Pill = styled.span`
  font-size: 8px;
  font-weight: 900;
  color: ${white};
  letter-spacing: 2px;
  text-align: center;
  background: ${brandColor2};
  background: #184179;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 108px;
  transform: translateY(-3px);
  margin-left: 10px;
`;

const Snippet = ({ title, highlight, content }) => {
  return (
    <SnippetWrapper>
      <Title>
        {title}
        {highlight && <Pill>{highlight}</Pill>}
      </Title>
      <Body20 theme={{ margins: "0" }}>{content}</Body20>
    </SnippetWrapper>
  );
};

const featuresTop = [
  {
    title: "Logistics",
    content:
      "We handle all order fulfillment, tracking, and redemption for digital and physical products."
  },
  {
    title: "Personalization",
    content:
      "Your Shop will feature AI-powered product recommendations and custom upsells."
  },
  {
    title: "Security & Fraud",
    content: "We keep your site running smoothly and free of fraudsters."
  }
];

const featuresBottom = [
  {
    title: "Customer Support",
    content:
      "We keep all your customers satisfied with swift and detailed responses to all inquiries. "
  },
  {
    title: "Integrated Content",
    content:
      "Feature commerce content in your Shops to help promote products and drive conversions."
  },
  {
    title: "Internationalization",
    content: "Foreign language and currency support are available."
  }
];

const CommerceShopSection = () => {
  return (
    <Background>
      <HeroTwoColumns background="transparent">
        <TopTitle>
          <Headline80>Our Commerce Toolkit</Headline80>
        </TopTitle>
        <TopContent>
          <ContentSnippet title="SHOPS" top="01">
            We design, build, and curate your branded Shop. <br /> Think
            AI-driven personalization and a custom <br /> storefront you love.
            No developer work required.
          </ContentSnippet>
        </TopContent>
      </HeroTwoColumns>

      <Middle>
        <Wrapper>
          <Circle>
            <CircleHighlight title="2-3x">
              Higher Conversion Rates when Compared to Affiliate Industry
              Averages
            </CircleHighlight>
          </Circle>
        </Wrapper>

        <ToolkitAnimation />
      </Middle>

      <HeroTwoColumns background="transparent">
        <PagePadding>
          {featuresTop.map((item, key) => (
            <Snippet key={`feature` + key} {...item} />
          ))}
        </PagePadding>
        <PagePadding>
          {featuresBottom.map((item, key) => (
            <Snippet key={`feature` + key} {...item} />
          ))}
        </PagePadding>
      </HeroTwoColumns>
    </Background>
  );
};

export default CommerceShopSection;
