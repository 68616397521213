import React from "react";
import styled from "styled-components";
import OutlinedText from "./styles/OutlinedText";
import { Headline64, Headline40 } from "./styles/Headings";
import { Body20 } from "./styles/BodyTypes";
import { black1, white } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";

const Row = styled.span`
  display: block;
`;

const SmallTitle = styled(Headline40)`
  margin: 0;
`;

const Content = styled(Body20)`
  margin: ${(props) => (props.small ? "16px 0 22px" : "28px 0")};

  @media (${mediaMedium}) {
    margin: 20px 0;
  }
`;

const ContentSnippet = ({
  title,
  top,
  children,
  color,
  small,
  contentWrapper
}) => {
  const Title = small ? Headline40 : Headline64;
  return (
    <>
      <Title theme={{ color: color ? white : black1, margins: "0" }}>
        <OutlinedText
          color={color ? white : black1}
          stroke={color ? white : black1}
          fill="transparent"
          component={small ? SmallTitle : Headline64}
          as="span"
        >
          {top}
        </OutlinedText>
        <Row>{title}</Row>
      </Title>
      <Content
        small={small}
        as={contentWrapper ?? "p"}
        theme={{ color: color ? white : black1 }}
      >
        {children}
      </Content>
    </>
  );
};

export default ContentSnippet;
